.ngx-datatable.material {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #fff !important;
}

.ngx-datatable {
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;

  .datatable-header {
    .datatable-header-cell {
      font-size: 15px !important;
      font-weight: 700 !important;
      margin: auto 10px !important;
      padding: 0.9rem 3px !important;
    }
  }

  .datatable-body {
    datatable-progress {
      width: 100%;
    }
  }

  .datatable-body-row {
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      ion-item {
        --background: #eee;
      }
    }
  }

  .datatable-row-wrapper:not(:last-of-type) > .datatable-body-row {
    border-bottom: 1px solid #e5e5e5;
  }

  datatable-selection {
    .empty-row {
      text-align: center !important;
    }
  }

  .datatable-body-cell {
    font-size: 16px !important;
    padding: 0px 3px !important;
    margin: auto 10px !important;

    ion-item {
      --padding-start: 0px;
      --inner-padding-end: 0px;

      &:hover {
        --background: #eee;
      }
    }

    span {
      word-break: break-all;
      white-space: normal;
    }
    .description-block {
      overflow-y: auto;
      max-height: 100px;
    }

    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }

  .datatable-footer {
    .datatable-footer-inner {
      height: auto !important;
    }

    @media screen and (max-width: 768px) {
      .datatable-footer-inner {
        flex-wrap: wrap;
        padding: 14px 0;
      }

      .datatable-pager {
        flex: 1 1 100% !important;
        text-align: center !important;
      }
    }

    .datatable-pager {
      .datatable-icon-left,
      .datatable-icon-right,
      .datatable-icon-prev,
      .datatable-icon-skip {
        padding: 2px 3px !important;
      }
      .pager {
        display: flex !important;
        align-items: center;
        justify-content: end;

        @media screen and (max-width: 580px) {
          justify-content: center;
        }

        li {
          display: flex !important;

          a {
            font-size: 16px;
          }
        }
      }
    }

    i.datatable-icon-left,
    i.datatable-icon-prev,
    i.datatable-icon-right,
    i.datatable-icon-skip {
      display: flex;
    }

    .page-count {
      font-size: 15px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.ngx-datatable.logs-viewer {
  .datatable-body-cell {
    text-transform: unset;
    white-space: pre-line;

    &:nth-child(1) {
      padding-left: 7px;
    }
  }
}

.ngx-datatable.body-cell-padding-custom {
  .datatable-body-cell {
    padding: 13px 3px !important;
  }
}

.ngx-datatable.body-cell-stacked {
  .datatable-header-cell {
    white-space: pre-wrap !important;
    padding: 0px 3px !important;
  }
}

.table-footer {
  font-size: 16px;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
