/* Importing Bootstrap SCSS file for bootstrap accordian module. */
// @import '~bootstrap/scss/bootstrap/';

.dropdown-content-container {
  ngb-accordion {
    .accordion {
      overflow-anchor: none;
    }

    .accordion > .card {
      overflow: hidden;
    }

    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px;
    }

    .card {
      border-radius: 0 !important;
      cursor: default !important;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;

      .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
      }

      .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0.8rem;

        .grand-child-item {
          padding: 0.3rem 1rem;
          border: 1px solid transparent;
          cursor: pointer;

          &:hover {
            border: 1px solid lightgrey;
          }
        }
      }
    }

    .btn {
      outline: none;
      text-decoration: none !important;
      box-shadow: none !important;
      text-align: left !important;
      color: var(--ion-color-medium) !important;
      background: transparent;

      span {
        font-weight: 400;
        font-size: 15px;
      }
    }

    span.selected {
      color: var(--ion-color-primary);
    }

    .card:not(:last-of-type) {
      .card-header {
        margin-bottom: unset !important;
      }
    }
  }
}
