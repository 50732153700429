.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.solid-button {
  background: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 5px;
  outline: none;
}

.ghost-button {
  color: var(--ion-color-medium-contrast) !important;
  background: var(--ion-color-medium) !important;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 5px;
  outline: none;
}

.ten--specialized-button {
  display: inline-block;
  background: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
  padding: 8px 20px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  outline: none;

  &:hover {
    opacity: 0.9;
  }
}

.delete-button,
.solid-button,
.ghost-button {
  padding: 10px 15px !important;
  border-radius: 5px !important;
  margin: 0 5px !important;

  &:hover {
    opacity: 0.9;
  }
}

.delete-button {
  background: var(--ion-color-danger) !important;
  color: var(--ion-color-danger-contrast) !important;
  outline: none;
}
