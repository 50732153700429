@import 'ngx-toastr/toastr';

.header-notification {
  .notification-body {
    .notification {
      .left-section {
        .notification-info {
          p {
            font-family: 'Poppins', sans-serif;
          }

          .message-container {
            .message {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              padding-right: 30px;
              font-size: 15px;
              color: #1e1e1e;

              @media (max-width: 1023px) {
                padding-right: 20px;
              }

              @media (max-width: 650px) {
                padding-right: 20px;
              }

              @media (max-width: 480px) {
                font-size: 15px;
                padding-right: 10px;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

.notification-page-container {
  .notification-body {
    .notification {
      .left-section {
        .notification-info {
          .message-container {
            .message {
              padding-right: 0px !important;
            }
          }
        }
      }
    }
  }
}

.toast-container {
  .toast-success {
    background-color: #fff;
    background-image: none;
  }

  .ngx-toastr {
    display: flex;
    flex-direction: column;
    width: 450px !important;
    padding: 8px !important;

    .notification {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      vertical-align: middle;
      cursor: pointer;

      .notification-title-container {
        .notification-title {
          font-size: 17px;
          font-weight: 600;
          margin: 0px 0px 6px 0px;
          color: #2b2b2b;
        }
      }

      .close {
        position: absolute;
        top: 4px;
        right: 3px;
        color: #000000;
      }

      .left-section {
        display: flex;
        justify-content: space-between;
        vertical-align: middle;

        .user-profile {
          min-width: 60px;

          img {
            margin: 7px;
          }
        }

        .broadcast-logo {
          min-width: 60px;
          padding: 7px;

          .image-container {
            // background: #f1f8ed;
            text-align: center;
            align-items: center;
            border-radius: 50%;
            height: 48px;
            width: 48px;
            margin: 7px;
            position: relative;

            img {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
              right: 0;
            }
          }
        }

        .notification-info {
          padding: 7px;

          .message-container {
            .message {
              color: #2b2b2b;
              font-size: 15px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                text-transform: capitalize;
                color: #000000;
              }
            }
          }

          .comment {
            font-size: 15px;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 10;
            -webkit-box-orient: vertical;
            margin: 10px auto;
            white-space: break-spaces;
            word-break: break-word;
          }

          .time {
            font-size: 13px;
            color: #2b2b2b;
          }
        }

        .broadcast-notification-info {
          .broadcast-subject {
            padding-top: 4px;
            font-weight: 500;
            color: #000000;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .broadcast-message {
            margin: 10px auto;
            font-size: 15px;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 12;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
