.ion-flex-container {
  display: flex;
}

.ion-flex-wrap {
  flex-wrap: wrap;
}

.ion-flex-flow-row-wrap {
  flex-flow: row wrap;
}

.ion-flex-direction-column {
  flex-direction: column;
}

.ion-flex-gap-default {
  gap: 10px;
}

.ion-flex-column {
  flex-direction: column;
}

.ion-flex-row {
  flex-direction: row;
}

.ion-flex {
  flex: 1;
}
