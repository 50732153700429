/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Poppins', sans-serif;

  .theme-default {
    --ion-color-primary: #8abf47;
    --ion-color-primary-rgb: 138, 191, 71;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #79a83e;
    --ion-color-primary-tint: #96c559;
    --ion-color-primary-light: #f1f8ed;

    --ion-color-secondary: #004e98;
    --ion-color-secondary-rgb: 0, 78, 152;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #004586;
    --ion-color-secondary-tint: #1a60a2;

    --ion-color-tertiary: #d3cdd7;
    --ion-color-tertiary-rgb: 211, 205, 215;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #bab4bd;
    --ion-color-tertiary-tint: #d7d2db;

    --ion-color-success: #0c7c59;
    --ion-color-success-rgb: 12, 124, 89;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0b6d4e;
    --ion-color-success-tint: #24896a;

    --ion-color-warning: #f7b32b;
    --ion-color-warning-rgb: 247, 179, 43;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d99e26;
    --ion-color-warning-tint: #f8bb40;

    --ion-color-danger: #fe5f55;
    --ion-color-danger-rgb: 254, 95, 85;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e0544b;
    --ion-color-danger-tint: #fe6f66;

    --ion-color-medium: #878787;
    --ion-color-medium-rgb: 135, 135, 135;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #777777;
    --ion-color-medium-tint: #939393;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

  .theme-bootstrap {
    --ion-color-primary: #0d6efd;
    --ion-color-primary-rgb: 13, 110, 253;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0b61df;
    --ion-color-primary-tint: #257dfd;
    --ion-color-primary-light: #f7f9ff;

    --ion-color-secondary: #6c757d;
    --ion-color-secondary-rgb: 108, 117, 125;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #5f676e;
    --ion-color-secondary-tint: #7b838a;

    --ion-color-tertiary: #0dcaf0;
    --ion-color-tertiary-rgb: 13, 202, 240;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #0bb2d3;
    --ion-color-tertiary-tint: #25cff2;

    --ion-color-success: #198754;
    --ion-color-success-rgb: 25, 135, 84;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #16774a;
    --ion-color-success-tint: #309365;

    --ion-color-warning: #ffc107;
    --ion-color-warning-rgb: 255, 193, 7;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0aa06;
    --ion-color-warning-tint: #ffc720;

    --ion-color-danger: #dc3545;
    --ion-color-danger-rgb: 220, 53, 69;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c22f3d;
    --ion-color-danger-tint: #e04958;

    --ion-color-medium: #878787;
    --ion-color-medium-rgb: 135, 135, 135;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #777777;
    --ion-color-medium-tint: #939393;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

  .theme-material {
    --ion-color-primary: #1976d2;
    --ion-color-primary-rgb: 25, 118, 210;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1668b9;
    --ion-color-primary-tint: #3084d7;
    --ion-color-primary-light: #e0eaf1;

    --ion-color-secondary: #2196f3;
    --ion-color-secondary-rgb: 33, 150, 243;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #1d84d6;
    --ion-color-secondary-tint: #37a1f4;

    --ion-color-tertiary: #673ab7;
    --ion-color-tertiary-rgb: 103, 58, 183;
    --ion-color-tertiary-contrast: #fff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5b33a1;
    --ion-color-tertiary-tint: #764ebe;

    --ion-color-success: #009688;
    --ion-color-success-rgb: 0, 150, 136;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #008478;
    --ion-color-success-tint: #1aa194;

    --ion-color-warning: #ffc107;
    --ion-color-warning-rgb: 255, 193, 7;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0aa06;
    --ion-color-warning-tint: #ffc720;

    --ion-color-danger: #f44336;
    --ion-color-danger-rgb: 244, 67, 54;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d73b30;
    --ion-color-danger-tint: #f5564a;

    --ion-color-medium: #878787;
    --ion-color-medium-rgb: 135, 135, 135;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #777777;
    --ion-color-medium-tint: #939393;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

  .theme-ionic {
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
    --ion-color-primary-light: #f7faff;

    --ion-color-secondary: #5260ff;
    --ion-color-secondary-rgb: 82, 96, 255;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4854e0;
    --ion-color-secondary-tint: #6370ff;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #fff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-medium: #878787;
    --ion-color-medium-rgb: 135, 135, 135;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #777777;
    --ion-color-medium-tint: #939393;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }
}
