app-editor {
  width: 100%;
}

.tox {
  .tox-dialog {
    box-shadow: none !important;
    border-width: 0 !important;
    border-radius: 4px !important;

    @media screen and (max-width: 768px) {
      align-self: center !important;
      width: calc(100vw - 10%) !important;
    }
  }

  .tox-dialog-wrap__backdrop {
    background-color: rgba(0, 0, 0, 0.32) !important;
  }

  .tox-dialog__body-nav-item--active {
    border-bottom: 2px solid var(--ion-color-primary) !important;
    color: var(--ion-color-primary) !important;
  }

  .tox-dialog__footer {
    @media screen and (max-width: 520px) {
      justify-content: center !important;
    }
  }

  .tox-listboxfield .tox-listbox--select:focus,
  .tox-textarea:focus,
  .tox-textfield:focus {
    border-color: var(--ion-color-primary) !important;
  }

  .tox-button--secondary {
    background-color: #ffffff !important;
    border-color: #333 !important;
    padding: 6px 18px !important;
    border-radius: 5px !important;
    margin: 0 5px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
  }

  .tox-button--secondary + .tox-button {
    background-color: var(--ion-color-primary) !important;
    border-color: var(--ion-color-primary) !important;
    padding: 6px 18px !important;
    border-radius: 5px !important;
    margin: 0 5px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
  }

  .tox-button--naked {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .tox-dialog__body-nav-item {
    @media screen and (max-width: 768px) {
      border-bottom: 2px solid #e2e2e2f5 !important;
      width: 33.333% !important;
      text-align: center !important;
      padding: 10px 0 !important;
    }
  }

  .tox-dialog__body-nav-item:focus {
    background-color: transparent !important;
  }

  .tox-dialog__body-nav-item--active {
    @media screen and (max-width: 768px) {
      border-bottom: 2px solid var(--ion-color-primary) !important;
    }
  }
}

.editor {
  .tox-tinymce {
    border-radius: 4px;
  }
}
