@media screen and (max-width: 480px) {
  .ion-padding {
    --padding-start: var(--ion-padding, 0px);
    --padding-top: var(--ion-padding, 0px);
    --padding-end: var(--ion-padding, 0px);
    --padding-bottom: var(--ion-padding, 0px);
  }
}

ion-content {
  ion-icon {
    pointer-events: none;
  }

  .general-main {
    height: 100%;

    .general-chat {
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 0%);
      background: #fff;
      height: 100%;
      position: sticky;
      width: 100%;
      overflow: hidden;

      .heading-wrapper {
        position: relative;

        .heading {
          top: 0;
          background: #fff;
          z-index: 9999999;
          position: inherit;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 480px) {
            display: none;
          }

          @media (max-width: 575px) {
            padding-right: 0;
            flex-wrap: wrap;
            padding-bottom: 8px;
            padding-top: 8px;
          }

          .channel-title {
            display: block;
            padding: 9px 16px;
            font-size: 22px;
            position: initial;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            line-height: 38px;
            text-transform: uppercase;

            @media (max-width: 575px) {
              font-size: 16px;
              padding-bottom: 0;
              padding-top: 0;
            }
          }

          .flex-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            .popout-window-icon {
              cursor: pointer;
              margin: auto 10px;
            }

            .search-icon {
              color: #656b70;
            }

            .toggle-block {
              display: flex;
              justify-content: space-between;
              align-items: center;

              span {
                font-size: 16px;
                font-weight: normal;
              }

              .toggle-switch {
                height: 20px;
                width: 45px;
                display: inline-block;
                position: relative;
                margin-bottom: 0px;
                margin: auto 6px;

                input {
                  display: none;

                  &:checked + .slider {
                    background-color: var(--ion-color-primary);
                  }

                  &:checked + .slider::after {
                    -webkit-transform: translateX(15px);
                    -ms-transform: translateX(15px);
                    transform: translateX(15px);
                  }
                }

                .slider {
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  background-color: var(--ion-color-medium);
                  transition: 0.5s;
                  border-radius: 20px;

                  &::after {
                    position: absolute;
                    content: '';
                    height: 16px;
                    width: 24px;
                    background-color: white;
                    left: 3px;
                    bottom: 2px;
                    transition: 0.5s;
                    border-radius: 7px;
                  }
                }
              }
            }
          }
        }

        .serach-bar-container {
          position: absolute;
          transform: translateY(-99%);
          padding: 7px 18px;
          z-index: 99999;
          width: 100%;
          background: #fff;
          border-bottom: 1px solid lightgray;

          .search-bar {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
            align-items: stretch;
            border-radius: 5px;
            border-width: 1px;
            border-color: #d5d9de;
            border-style: solid;
            max-width: 700px;

            @media (max-width: 767px) {
              max-width: unset;
            }

            ion-searchbar {
              --box-shadow: unset;

              .searchbar-input-container {
                .searchbar-search-icon {
                  width: 19px;
                  height: 19px;
                }

                .searchbar-input {
                  line-height: 28px;
                }
              }
            }

            .search-info {
              position: relative;
              display: flex;
              flex-shrink: 0;
              align-items: center;
              background-color: rgba(0, 0, 0, 0);
              border-width: 0px 0px 0px 1px;
              border-style: solid;
              border-color: rgb(213, 217, 222);
              gap: 7px;

              .action-button,
              .search-count {
                padding: 7px;
                color: var(--ion-color-medium);
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 1px;
              }

              ion-buttons {
                ion-button {
                  --padding-top: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  --padding-start: 6px;
                  --border-radius: 0%;
                  margin-left: 0;
                  margin-right: 0;
                  margin-top: 0;
                  margin-bottom: 0;
                  width: auto;
                  height: 40px;
                  text-transform: capitalize;

                  ion-icon {
                    font-size: 1.3em;
                  }
                }
              }
            }
          }
        }
      }

      .scrollbar {
        width: 100%;
        min-width: 150px;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        align-items: stretch;
        align-self: stretch;
        // height: calc(100vh - 230px) !important;
      }

      .chart-area-container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        align-items: stretch;
        height: calc(100vh - 180px);

        @media (max-width: 767px) {
          height: calc(100vh - 160px);
        }

        @media (max-width: 480px) {
          height: 100%;
        }

        .chat-area {
          .chat-area-body {
            @media (max-width: 480px) {
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }

          .thread-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 8px 8px 0;
            position: relative;
            border-left: 4px solid transparent;

            &:hover {
              background-color: var(--ion-color-light);
            }

            .inner-reaction {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;

              .user-image {
                display: block;
                text-align: center;
                position: relative;
                overflow: visible;

                img {
                  height: 50px;
                  width: 50px;
                  border-radius: 100%;
                  object-fit: cover;
                }

                .crew {
                  font-size: 10px;
                  border-radius: 3px;
                  text-align: center;
                  color: var(--ion-color-primary-contrast);
                  font-weight: 700;
                  z-index: 999;
                  text-transform: uppercase;
                  position: absolute;
                  left: -23%;
                  right: -20%;
                  margin: 0 auto;
                  max-width: 53px;
                  top: 32px;
                  bottom: unset;
                  padding: 0px 3px;
                  width: fit-content;
                  background-color: var(--ion-color-primary);

                  &:after {
                    content: '';
                    width: 100%;
                    height: 8px;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                  }
                }

                .crew-border {
                  border: 2px solid var(--ion-color-primary) !important;
                }
              }

              .message-wrapper {
                border-radius: 0px;
                padding: 0;
                width: 100%;

                .embed-content-container {
                  .embeded-thread-text {
                    word-break: break-word;
                    white-space: pre-line;
                    font-size: 14px;
                    color: #535353;
                  }

                  .holds-the-iframe {
                    background: url('/assets/icon/iframe-loading.gif') center center no-repeat;
                    background-color: #eee;
                    margin: 5px 0;
                    border-radius: 8px;
                  }

                  .holds-the-image,
                  .holds-the-iframe {
                    width: 50%;

                    @media (max-width: 1366px) {
                      width: 70%;
                    }

                    @media (max-width: 1024px) {
                      width: 90%;
                    }

                    @media (max-width: 767px) {
                      width: 100%;
                    }

                    .iframe-container {
                      position: relative;
                      padding-bottom: 56.25%;
                      margin: 0 0;

                      iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                      }
                    }

                    img {
                      width: 100%;
                      border-radius: 8px;
                      margin: 8px 0;
                      cursor: pointer;
                    }
                  }
                }

                .name {
                  font-size: 15px;
                  font-weight: 600;
                  display: flex;
                  color: #2b2b2b;

                  @media (max-width: 368px) {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                  }
                }

                .time {
                  font-size: 12px;
                  color: #84888c;
                  margin-top: 4px;
                  margin-left: 8px;
                  font-weight: 500;
                }

                .bot-badge {
                  margin: auto 0px auto 7px;
                  font-size: 11px;
                  background: #5865f2;
                  color: #fff;
                  padding: 0px 6px;
                  border-radius: 4px;
                }

                .content {
                  font-size: 14px;
                  color: #535353;
                  overflow-wrap: anywhere;
                  font-weight: 400;
                  line-height: 20px;
                  white-space: pre-line;
                }

                .embed-content-container {
                  display: flex;
                  flex-direction: column;
                }
              }

              .emoji-container {
                display: flex;
                cursor: pointer;
                margin: 6px 0px 6px 0px;
                width: 325px;

                .emoji-count {
                  position: relative;
                  border: 1px solid #50a2c5;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  width: auto;
                  padding: 0px 5px;
                  display: flex;
                  flex-direction: row;
                  border-radius: 50px;
                  margin-right: 4px;
                  scrollbar-width: thin;
                  cursor: pointer;
                  align-items: center;
                  align-self: center;
                  display: flex;
                  border-radius: 30px;

                  p {
                    margin-bottom: 0px;
                    font-size: 13px;
                    position: relative;
                    top: -1px;
                    left: -3px;
                  }

                  ion-icon {
                    cursor: pointer;
                  }

                  span {
                    font-size: 13px;
                    color: #1566a4;
                    font-weight: 700;
                    padding: 0px 2px;
                  }
                }
              }
            }

            .thread-with-parent {
              .user-image {
                margin-top: 29px;

                @media (max-width: 415px) {
                  margin-top: 29px;
                }
              }

              .title {
                .message-wrapper {
                  position: relative;
                  padding: 0;
                  background: transparent;

                  &::before {
                    content: '';
                    border: 2px solid #4f545b;
                    border-top-left-radius: 6px;
                    border-bottom: 0;
                    border-right: 0;
                    width: 33px;
                    position: absolute;
                    left: -36px;
                    right: 0;
                    top: 8px;
                    height: 14px;
                  }

                  .parent-user-name {
                    font-weight: 600;
                    white-space: nowrap;
                  }

                  .parent-thread-info {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-size: 13px;
                    text-transform: capitalize;
                    margin-bottom: 3px;

                    @media (min-width: 813px) and (max-width: 1025px) {
                      align-items: flex-start;
                    }

                    @media (max-width: 767px) {
                      align-items: flex-start;
                      flex-wrap: wrap;
                    }

                    .parent-user-image {
                      display: flex;
                      justify-content: center;
                      position: relative;
                      flex-wrap: wrap;
                      user-select: none;

                      @media (min-width: 813px) and (max-width: 1040px) {
                        width: 20px;
                      }

                      img {
                        height: 16px !important;
                        width: 16px !important;
                        border-radius: 100%;
                        object-fit: cover;
                      }

                      .crew {
                        font-size: 10px;
                        border-radius: 3px;
                        text-align: center;
                        color: white;
                        padding: 2px 6px;
                        font-weight: 700;
                        z-index: 999;
                        position: relative;
                        text-transform: uppercase;
                        letter-spacing: 0.1px;
                        bottom: 8px;

                        &:after {
                          content: '';
                          width: 100%;
                          height: 8px;
                          background: #fff;
                          position: absolute;
                          bottom: -8px;
                          left: 0;
                        }
                      }

                      .crew-border {
                        border: 2px solid var(--ion-color-primary) !important;
                      }
                    }

                    .parent-thread-text {
                      cursor: pointer;
                      color: #535353;
                      word-break: break-word;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      line-clamp: 2;
                      -webkit-box-orient: vertical;
                      width: 80%;

                      @media (max-width: 460px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            .popover-content.sc-ion-popover-md {
              width: auto !important;
            }

            .reaction {
              visibility: hidden;
              position: absolute;
              right: 6px;
              top: 0;

              .main {
                background: #ddd;

                .inner {
                  height: 33px !important;

                  ion-icon {
                    color: #6d6d6d;
                    cursor: pointer;
                    padding: 8px;
                    font-size: 18px;
                  }

                  &:hover {
                    .tool {
                      position: relative;
                      display: inline-block;
                      border-bottom: 1px dotted black;
                    }

                    .tool .tooltext {
                      visibility: hidden;
                      width: 150px;
                      font-size: 12px;
                      background-color: black;
                      color: #fff;
                      text-align: center;
                      border-radius: 6px;
                      padding: 5px 0;
                      position: absolute;
                      z-index: 1;
                      top: -5px;
                    }

                    .tool:hover .tooltext {
                      visibility: visible;
                    }
                  }
                }
              }

              .justify-content-end {
                border: 1px solid #dddddd;
                border-radius: 4px;
                margin: 8px 2px;
              }
            }

            .title {
              display: flex;
              flex-wrap: wrap;
              width: calc(100% - 55px);
              position: relative;
              margin: 5px 0px 0px 0px;
            }

            &:hover .reaction {
              visibility: visible;
              cursor: pointer;
            }
          }

          .highlited-thread-container,
          .parent-highlited-thread {
            background-color: var(--ion-color-medium);
            border-left: 4px solid var(--ion-color-primary);
            color: #fff;

            &:hover {
              background-color: var(--ion-color-medium);
              border-left: 4px solid var(--ion-color-primary);
            }

            .title {
              .message-wrapper {
                &::before {
                  border-color: #fff !important;
                  opacity: 0.8;
                }

                .parent-thread-text,
                .parent-user-name,
                .content,
                .embeded-thread-text,
                .name {
                  color: #fff !important;

                  .time {
                    opacity: 0.6;
                    color: #fff !important;
                  }
                }

                .parent-user-name,
                .content {
                  opacity: 0.8;
                }

                .embeded-thread-text {
                  app-chat-files-preview {
                    .ngx-link-preview-container {
                      .og-link-preview-file {
                        .preview-file {
                          background: transparent;
                        }
                      }
                    }
                  }
                }
              }

              .emoji-container {
                .emoji-count {
                  background-color: #fff;
                  border-color: var(--ion-color-medium);
                }
              }
            }

            .reaction {
              .main {
                background: var(--ion-color-medium);

                ion-icon {
                  color: #fff !important;
                }
              }
            }
          }

          .parent-highlited-thread {
            -webkit-transition: background-color 500ms linear;
            -ms-transition: background-color 500ms linear;
            transition: background-color 500ms linear;
          }

          .divider-block {
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1px;
            background: #ddd;
            margin: 20px 0;
            width: 100%;

            span {
              background: #fff;
              border-radius: 2px;
              padding: 3px 20px;
              border-radius: 30px;
              font-size: 13px;
              border: 1px solid #ddd;
              position: relative;
              top: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              color: #444;
            }
          }
        }

        .message-box-container {
          position: relative;
          width: 100%;
          z-index: 0;
          margin: 12px 0;
          padding: 0 12px;
          bottom: 0;

          .file-preview-container {
            display: inline-flex;
            flex-wrap: wrap;
            padding: 10px 0;
            background: white;
            width: 100%;
            max-height: 300px;
            overflow: auto;

            .sub-container {
              @media (max-width: 360px) {
                width: 100%;
              }

              .preview-file {
                max-width: 400px;
                display: flex;
                align-items: center;
                margin: auto 6px 6px auto;
                justify-content: space-between;
                border: 1px solid lightgray;
                border-radius: 7px;
                padding: 8px;
                gap: 10px;
                background: #ffffff;
                position: relative;

                @media (max-width: 360px) {
                  justify-content: unset;
                }

                &:hover {
                  span.close-icon {
                    opacity: 1;
                  }
                }

                span.close-icon {
                  cursor: pointer;
                  opacity: 0;
                  right: -6px;
                  top: -6px;
                  position: absolute;
                  width: 13px;
                  height: 13px;
                  border-radius: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #8c8c8c;
                  color: white;
                  font-size: 11px;
                  font-weight: 600;

                  @media (max-width: 1024px) {
                    opacity: 1;
                  }
                }

                p {
                  margin: 0;
                  padding: 0;
                }

                .preview-file-icon {
                  width: 45px;
                  height: 45px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #f1f1f1;
                  border-radius: 4px;
                }

                .preview-file-data {
                  .preview-file-name {
                    font-size: 13px;
                    font-weight: 600;
                    max-width: 150px;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  }

                  .preview-file-size {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .parent-thread-details {
            background: var(--ion-color-medium);
            color: #fff;
            font-size: 13px;
            padding: 6px 11px 6px 11px;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            letter-spacing: 0.5px;
            position: relative;

            .reply-author {
              font-weight: bold;
              text-transform: capitalize;
              letter-spacing: 0px;
            }

            .btn-close-thread {
              float: right;
              background: #fff;
              font-size: 11px;
              border-radius: 50%;
              margin: 0;
              padding: 0px 3px;
              color: #2f3136;
              cursor: pointer;
              display: flex;
              align-items: center;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 12px;
              height: 16px;
            }
          }

          .message-box {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: 0;
            overflow: hidden;
            align-items: flex-end;
            border-color: rgba(0, 0, 0, 0);
            border-style: solid;
            border-width: 3px;
            background-color: var(--ion-color-light);
            border-radius: 25px;

            @media (max-width: 992px) {
              position: relative;
            }

            .message-send-button {
              display: block;
            }

            .add-files-button {
              display: none;
            }

            .text-area-wrap {
              width: 100%;

              .message-textarea {
                width: 100%;
                border: none;
                padding: 6px;
                outline: none;
                -webkit-appearance: none !important;
                background-color: var(--ion-color-light);
              }
            }
          }

          .has-empty-input {
            .message-send-button {
              display: none;
            }

            .add-files-button {
              display: block;
            }
          }

          .message-box-reply-mode {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
          }

          .disabled-message-box {
            cursor: not-allowed;

            .message-textarea {
              border-left: 1px solid lightgrey;
            }

            .action-section-left {
              padding: 0px 5px;

              @media (max-width: 480px) {
                padding: 0px;
                justify-content: center !important;
                flex-wrap: wrap !important;
              }

              ion-button {
                --padding-start: 0.2em;
                --padding-end: 0.2em;

                @media (max-width: 480px) {
                  height: auto;
                  margin: 2px;
                  --padding-start: 0em;
                  --padding-end: 0em;
                }
              }
            }

            .action-section {
              cursor: not-allowed;

              ion-button::part(native) {
                cursor: not-allowed;
              }
            }

            textarea {
              cursor: not-allowed;
            }
          }
        }
      }

      .chart-area-searchbar-activated {
        margin-top: 60px;
        height: calc(100vh - 240px);

        @media (max-width: 767px) {
          height: calc(100vh - 220px);
        }
      }
    }

    .no-data-container {
      height: 100%;
      width: 100%;
      border: 1px solid #e5e5e5;
      position: relative;
      background: #fff;
      overflow: auto;

      .no-data {
        margin: 20px auto;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        text-align: center;

        img {
          margin-bottom: 20px;
        }

        h3 {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 4px;
          color: #84888c;
        }

        p {
          font-size: 16px;
          color: #89969c;
        }
      }
    }
  }

  .slide-in {
    visibility: visible;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  .slide-out {
    visibility: visible;
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }

  @keyframes slide-in {
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateY(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(-115%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateY(0%);
    }

    100% {
      -webkit-transform: translateY(-115%);
    }
  }
}
