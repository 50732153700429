$color_1: #212529;
$color_2: #fff;
$color_3: #6c757d;
$border-color_1: #6c757d;
$border-color_2: #ced4da;

.ten-gallery {
  z-index: 9999;
}

.gl-toolbar {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 3px;
  position: relative;

  .gl-toolbar-left {
    ion-buttons {
      ion-button {
        text-transform: capitalize;
      }
    }
  }
}
.file-list {
  .gallery-seachbar {
    max-width: 200px;

    .searchbar-input-container {
      height: 29px !important;
      background: #fff !important;
      border: 1px solid #dee2e6 !important;
      margin: 0;
      padding-left: 30px;

      input.searchbar-input {
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        margin: 0;
        padding: 0;
        border-color: $border-color_2;
        height: 26px;
        background: none;
        box-shadow: none;
        text-indent: 0;
        min-width: 0;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .searchbar-search-icon.sc-ion-searchbar-md {
        left: 5px;
        top: 5px;
        width: 18px;
        height: 18px;
        color: $color_3;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .total-files-info {
    display: none;
  }

  .normal-tab {
    table.file-table-list {
      tr.file-list__header {
        th {
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
      tr.file-list__file {
        td {
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }

          .file-item {
            .image-title {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
  .trash-tab {
    table.file-table-list {
      tr.file-list__header {
        th {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
      tr.file-list__file {
        td {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
  }

  .file-list {
    ion-searchbar.search {
      width: 140px;
    }
  }

  .gallery-body {
    .file-manager {
      .files-container {
        .file-list {
          .file-breadcrumb {
            min-height: 41px;
            display: inline-block;
            width: 100%;
            ul.breadcrumb-ul {
              padding: 8px 150px 8px 8px;
            }
          }
        }
      }
    }
  }
}
